/* eslint-disable max-len */
/* Don't edit the content of this file.
Don't commit this file.
This file was created automatically. */
export const DefaultThemeTokens = {
    "primary": {
        "level50": "#F2E5FF",
        "level100": "#D9B3FF",
        "level200": "#BF80FF",
        "level300": "#A64CFF",
        "level400": "#8C1AFF",
        "level500": "#8000FF",
        "level800": "#37006D",
        "level600": "#6600CC",
        "level700": "#4D0099",
        "level900": "#27004D",
    },
    "secundary": {
        "level0": "#ffffff",
        "level100": "#e5e5e5",
        "level200": "#cccccc",
        "level300": "#b3b3b3",
        "level400": "#999999",
        "level500": "#808080",
        "level600": "#666666",
        "level700": "#4c4c4c",
        "level800": "#333333",
        "level1000": "#000000",
    },
    "base": {
        "level0": "#ffffff",
        "level100": "#e5e5e5",
        "level200": "#cccccc",
        "level300": "#b3b3b3",
        "level400": "#999999",
        "level500": "#808080",
        "level600": "#666666",
        "level700": "#4c4c4c",
        "level800": "#333333",
        "level1000": "#000000",
    },
    "system": {
        "error": {
            "level50": "#fdebe8",
            "level100": "#f9c3b9",
            "level200": "#f69b8a",
            "level300": "#f2725b",
            "level400": "#ee4a2c",
            "level500": "#ec3615",
            "level600": "#bd2b11",
            "level700": "#8e200d",
            "level800": "#5e1608",
            "level900": "#2f0b04",
        },
        "success": {
            "level50": "#e9fbef",
            "level100": "#bef4d0",
            "level200": "#93ecb1",
            "level300": "#68e592",
            "level400": "#3dde73",
            "level500": "#27da64",
            "level600": "#1fae50",
            "level700": "#17833c",
            "level800": "#105728",
            "level900": "#093a19",
        },
        "warning": {
            "level50": "#fffae9",
            "level100": "#fff0bd",
            "level200": "#ffe790",
            "level300": "#ffdd64",
            "level400": "#ffd338",
            "level500": "#ffce22",
            "level600": "#cca51b",
            "level700": "#997c14",
            "level800": "#66520e",
            "level900": "#332907",
        },
    },
} as const