/* eslint-disable max-len */
/* Don't edit the content of this file.
Don't commit this file.
This file was created automatically. */
export const FontsTokens = {
    "fontFamilies": {
        "Primary": "Silka",
        "Secondary": "Inter",
    },
    "weights": {
        "Primary": {
            "thin": "100",
            "extralight": "200",
            "light": "300",
            "regular": "400",
            "medium": "500",
            "semibold": "600",
            "bold": "700",
            "black": "800",
        },
        "Secondary": {
            "thin": "100",
            "extralight": "200",
            "light": "300",
            "regular": "400",
            "medium": "500",
            "semibold": "600",
            "bold": "700",
            "black": "800",
        },
    },
    "sizes": {
        "Primary": {
            "xs": {
                "lineHeight": "1.6rem",
                "fontSize": "1.2rem",
                "letterSpacing": "0",
            },
            "sm": {
                "lineHeight": "2.0rem",
                "fontSize": "1.4rem",
                "letterSpacing": "0",
            },
            "base": {
                "lineHeight": "2.4rem",
                "fontSize": "1.6rem",
                "letterSpacing": "0",
            },
            "lg": {
                "lineHeight": "3.0rem",
                "fontSize": "1.8rem",
                "letterSpacing": "0",
            },
            "xl": {
                "lineHeight": "3.2rem",
                "fontSize": "2.0rem",
                "letterSpacing": "0",
            },
            "2xl": {
                "lineHeight": "3.4rem",
                "fontSize": "2.4rem",
                "letterSpacing": "0",
            },
            "3xl": {
                "lineHeight": "4.5rem",
                "fontSize": "3.0rem",
                "letterSpacing": "0",
            },
            "4xl": {
                "lineHeight": "5.2rem",
                "fontSize": "3.6rem",
                "letterSpacing": "0",
            },
            "5xl": {
                "lineHeight": "7.2rem",
                "fontSize": "4.8rem",
                "letterSpacing": "0",
            },
            "6xl": {
                "lineHeight": "8.6rem",
                "fontSize": "6.0rem",
                "letterSpacing": "0",
            },
            "7xl": {
                "lineHeight": "10.8rem",
                "fontSize": "7.2rem",
                "letterSpacing": "0",
            },
        },
        "Secondary": {
            "xs": {
                "lineHeight": "1.6rem",
                "fontSize": "1.2rem",
                "letterSpacing": "0",
            },
        },
    },
} as const