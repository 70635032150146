/* eslint-disable max-len */
export const Paths = {
    Home: "/",
    Components: "/components",
    HowItWorks: "/how-it-works-design",
    HowItWorksDesign: "/how-it-works-design",
    HowItWorksDevelop: "/how-it-works-development",
    AboutUs: "https://zeppelinlabs.io/",
    Github: "https://github.com/zeppelinlabs/util-components",
    Figma: "https://www.figma.com/community/file/1207008567687928032",
    FigmaHelp: "https://help.figma.com/hc/en-us/articles/360041051154-Guide-to-libraries-in-Figma",
    TokensStudio: "https://tokens.studio/",
    TokensStudioDocs: "https://docs.tokens.studio/",
    GithubResetStyles: "https://github.com/zeppelinlabs/util-components/blob/dev/src/styles/resetStyled.ts",
    GithubGlobalStyles: "https://github.com/zeppelinlabs/util-components/blob/dev/src/styles/globalStyled.ts",
    zeppelinSocialMedia: [
        {
            text: "Twitter",
            link: "https://twitter.com/Zeppelin_Labs",
        },
        {
            text: "Linkedin",
            link: "https://www.linkedin.com/company/zeppelinlabs/",
        },
        {
            text: "Dribbble",
            link: "https://dribbble.com/zeppelinlabs",
        },
        {
            text: "Instagram",
            link: "https://www.instagram.com/zeppelin_labs",
        },
        {
            text: "hello@zeppelinlabs.io",
            link: "mailto:hello@zeppelinlabs.io",
        },
    ],
    GithubComponents: {
        Tag: "https://github.com/zeppelinlabs/util-components/blob/dev/src/components/Tag",
        Tooltip: "https://github.com/zeppelinlabs/util-components/blob/dev/src/components/Tooltip",
        Button: "https://github.com/zeppelinlabs/util-components/blob/dev/src/components/Button",
        Switch: "https://github.com/zeppelinlabs/util-components/blob/dev/src/components/Form/Switch",
        Label: "https://github.com/zeppelinlabs/util-components/blob/dev/src/components/Form/Label",
        Checkbox: "https://github.com/zeppelinlabs/util-components/blob/dev/src/components/Form/Checkbox",
        RadioButton: "https://github.com/zeppelinlabs/util-components/blob/dev/src/components/Form/RadioButton",
        Input: "https://github.com/zeppelinlabs/util-components/blob/dev/src/components/Form/Input",
        Select: "https://github.com/zeppelinlabs/util-components/blob/dev/src/components/Form/Select",
    },
    Tag: "/components/tag",
    Tooltip: "/components/tooltip",
    Button: "/components/button",
    Switch: "/components/form/switch",
    Label: "/components/form/label",
    Checkbox: "/components/form/checkbox",
    RadioButton: "/components/form/radioButton",
    Input: "/components/form/input",
    Select: "/components/form/select",
}
